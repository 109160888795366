import React, { useState  } from 'react';
import { Link } from "gatsby"

import Layout from "../components/layout"
import CarbonPassHeader from "../components/carbonpassheader2"
import GetInTouch from "../components/getintouch"
import { CheckIcon, MailIcon, PhoneIcon, LockClosedIcon  } from '@heroicons/react/solid'
import {GlobeIcon, CurrencyPoundIcon} from '@heroicons/react/outline'
import iconweb from "../images/icon-web.svg"
import AIR from "../images/air.png"
import SEA from "../images/sea.png"
import CAR from "../images/logo-app2.svg"

import POJ from "../images/pojcrest.png"

const total = '$141.92'


const stats = [
  { name: 'Total Subscribers', stat: '71,897' },
  { name: 'Avg. Open Rate', stat: '58.16%' },
  { name: 'Avg. Click Rate', stat: '24.57%' },
]
const people = [
  {
    name: 'Birmingham',
    email: '£1.05',
    role: '40 CO2e kg carbon',
  },
  {
    name: 'Bristol',
    email: '£0.72',
    role: '27.6 CO2e kg carbon',
  },
  {
    name: 'East Midlands',
    email: '£1.11',
    role: '41.2 CO2e kg carbon',
  },
  {
    name: 'Exeter',
    email: '£0.59',
    role: '22.5 CO2e kg carbon',
  },
  {
    name: 'Glasgow',
    email: '£0.72',
    role: '27.6 CO2e kg carbon',
  },
  {
    name: 'Guernsey',
    email: '£0.22',
    role: '8.2 CO2e kg carbon',
  },
  {
    name: 'Liverpool',
    email: '£1.80',
    role: '68.6 CO2e kg carbon',
  },
  {
    name: 'London Gatwick',
    email: '£1.31',
    role: '56.6 CO2e kg carbon',
  },
  {
    name: 'London Heathrow',
    email: '£1.49',
    role: '56.6 CO2e kg carbon',
  },
  {
    name: 'London Luton',
    email: '£1.44',
    role: '54.8 CO2e kg carbon',
  },
  {
    name: 'Manchester',
    email: '£1.65',
    role: '62.9 CO2e kg carbon',
  },
  {
    name: 'Southamption',
    email: '£0.62',
    role: '23.7 CO2e kg carbon',
  },
  {
    name: 'Other',
    email: '£1.00',
    role: '',
  }

]

const pricing = [
  {
    email: '£1.00',
    role: '',
  },
  {
    email: '£5.00',
    role: '',
  },
  {
    email: '£10.00',
    role: '',
  },
  {
    email: '£15.00',
    role: '',
  },
  {
    email: '£26.25',
    role: '(1 tonne of carbon / 8 trees)',
  }
]


const modes = [
  {
    name: 'AIR',
    class:'col-span-1 flex flex-col text-center rounded-lg shadow divide-y divide-gray-200 bg-carbonblue-light'
  },
  {
    name: 'SEA',
    class:'col-span-1 flex flex-col text-center rounded-lg shadow divide-y divide-gray-200 bg-carbonblue'
  },
  {
    name: 'CAR',
    class:'col-span-1 flex flex-col text-center rounded-lg shadow divide-y divide-gray-200 bg-carbongreen'
  },

  // More people...
]

const steps = [
  { id: 1, name: 'Travel mode', href: '#', status: 'complete' },
  { id: 2, name: 'Destinaton', href: '#', status: 'current' },
  { id: 3, name: 'Jouney details', href: '#', status: 'upcoming' },
  { id: 4, name: 'Checkout', href: '#', status: 'upcoming' },
  { id: 5, name: 'Payment', href: '#', status: 'upcoming' },
]

const user = {
  name: 'Rebecca Nicholas',
  role: 'Product Designer',
  imageUrl:
    'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
}
const stats2 = [
  { label: 'CO2e kg carbon offset', value: 2041.2 },
  { label: '', value: " " },
]


const SecondPage = () => {
  const [content, setContent] = useState("aa");
  const [stage, setStage] = useState(1);


  return (
  <>

<Layout>
      <CarbonPassHeader/>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:py-10 mt-0">



      <ul role="list" className="divide-y divide-gray-200">
    <li>
      <a href="/carbon" className="block hover:bg-gray-50">
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-center">
          <div className="h-20 w-20 flex-shrink-0">
                    <img className=" h-20" src={POJ} alt="" />
                    </div>
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4 mb-2">
              <div>
                <p className="text-xl text-gray-900 truncate">Port of Jersey</p>
                <p className="mt-2 flex items-center text-sm text-gray-500 mb-2">
                <a href="//www.ports.je" target="_blank" className='flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50'>
                  <img className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" src={iconweb}  alt="icon website"/>
                  <span className="truncate ">www.ports.je</span></a>
                </p>
              </div>
              <div className="block">
                <div>
                  <p className="text-sm text-gray-900  hidden md:block">
                    Last updated: 
                    <time datetime="2020-01-07"> January 7, 2022</time>
                  </p>
                  <p className="mt-2 flex items-center text-sm text-gray-500">
           
                  
                    {stats2.map((stat) => (
          <div key={stat.label}>
            <span className="text-gray-900 text-2xl text-right">{stat.value}</span><br/><span className="text-gray-600">{stat.label}</span>
          </div>
        ))} 
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
<button className='flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50'>
           X BACK</button>
          </div>
        </div>
      </a>
    </li></ul>




      {/* <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
        {stats.map((item) => (
          <div key={item.name} className="px-4 py-5 bg-carbongreen shadow rounded-lg overflow-hidden sm:p-6">
            <dt className="text-sm font-medium text-white truncate">{item.name}</dt>
            <dd className="mt-1 text-3xl font-semibold text-white">{item.stat}</dd>
          </div>
        ))}
      </dl> */}

    
    <nav aria-label="Progress" className="mt-10 mb-10">
      <ol role="list" className="border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0">
        {steps.map((step, stepIdx) => (
          <li key={step.name} className="relative md:flex-1 md:flex">
            {step.id < stage  ? (
              <a onClick={() => setStage(step.id)} className="group flex items-center w-full">
                <span className="px-6 py-4 flex items-center text-sm font-medium">
                  <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-carbonblue-light rounded-full group-hover:bg-indigo-800">
                    <CheckIcon className="w-6 h-6 text-white" aria-hidden="true" />
                  </span>
                  <span className="ml-4 text-base text-gray-900">{step.name}</span>
                </span>
              </a>
            ) : step.id === stage ? (
              <a onClick={() => setStage(step.id)} className="px-6 py-4 flex items-center text-sm font-medium" aria-current="step">
                <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-carbonblue-light rounded-full">
                  <span className="text-carbonblue-light text-xl">{step.id}</span>
                </span>
                <span className="ml-4 text-xl text-carbonblue-light">{step.name}</span>
              </a>
            ) : (
              <a onClick={() => setStage(step.id)} className="group flex items-center">
                <span className="px-6 py-4 flex items-center text-sm font-medium">
                  <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                    <span className="text-gray-500 group-hover:text-gray-900  text-xl">{step.id}</span>
                  </span>
                  <span className="ml-4 text-base text-gray-500 group-hover:text-gray-900">{step.name}</span>
                </span>
              </a>
            )}

            {stepIdx !== steps.length - 1 ? (
              <>
                {/* Arrow separator for lg screens and up */}
                <div className="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
                  <svg
                    className="h-full w-full text-gray-300"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
    
      {/* stage 1 */}
      { stage === 1 &&
      <div>
        <h2 className="mt-2 mb-6 md:mt-6 text-3xl leading-8  sm:text-4xl md:mb-10  text-center">Select travel method</h2>
    <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-1  lg:grid-cols-3">
      {modes.map((person) => (
        <li
          key={person.name}
          
          className={person.class}
        >
          <div className="flex-1 flex flex-col p-8">
          <a onClick={() => setStage(2)}>
            { person.name === "AIR" && <img className="w-32 h-32 flex-shrink-0 mx-auto rounded-full" src={AIR} alt="" /> }
            { person.name === "CAR" && <img className="w-32 h-32 flex-shrink-0 mx-auto rounded-full" src={CAR} alt="" /> }
            { person.name === "SEA" && <img className="w-32 h-32 flex-shrink-0 mx-auto rounded-full" src={SEA} alt="" /> }

            <h3 className="mt-6  text-2xl  text-white">{person.name}</h3>
            </a>
          </div>
        </li>
      ))}
    </ul></div> }


      {/* stage 2 */}
      { stage === 2 &&
      <div><div className="grid grid-cols-1  " ><div>
      <h2 className="mt-0 mb-6 text-3xl leading-8  sm:text-4xl md:mb-4  text-center">Select departure airport</h2></div>
      <div className="mt-0 text-center w-full">
        <label for="search" className="sr-only">Search</label>
        <input type="email" name="search" id="search" className="w-full max-w-full lg:max-w-sm m-auto shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="Search"/>
       </div>
       </div>
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 my-10">
       
      {people.map((person) => (
        <div
          key={person.email}
          className="h-24 relative rounded-lg border border-gray-300 bg-carbongreen px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
        >
          <div className="flex-shrink-0">
            <GlobeIcon className="w-10 h-10 text-white" aria-hidden="true" />
          </div>
          <div className="flex-1 min-w-0 block">
            <a href="#" className="focus:outline-none" onClick={() => setStage(3)} >
              <span className="absolute inset-0" aria-hidden="true" />
              <p className="text-2xl font-medium  text-white">{person.name} <br/><span className="text-sm  text-white truncate">{person.role}</span></p>
              <div className='absolute top-4 right-4'><p className="text-4xl text-white mt-2">{person.email}</p></div>
              
            </a>
          </div>
        </div>
      ))}</div>
      </div>

  }

 {/* stage 3 Car */}
 { stage === 33 &&

<div className="space-y-6">



  <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
    <div className="md:grid md:grid-cols-3 md:gap-6">
      <div className="md:col-span-1">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Car Journey</h3>
        <p className="mt-1 text-sm text-gray-500">Balance your car emissions</p>
      </div>
      <div className="mt-5 md:mt-0 md:col-span-2">
        <form className="space-y-6" action="#" method="POST">

        <div>
      <label htmlFor="price" className="block text-sm font-medium text-gray-700">
      Distance traveled
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <input
          type="text"
          name="price"
          id="price"
          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
          aria-describedby="price-currency"
        />
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <span className="text-gray-500 sm:text-sm" id="price-currency">
            Miles
          </span>
        </div>
      </div>
    </div>

  
        <div className="col-span-6 sm:col-span-3">
              <label htmlFor="country" className="block text-sm font-medium text-gray-700">
              Fuel type
              </label>
              <select
                id="country"
                name="country"
                autoComplete="country-name"
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option>Petrol</option>
                <option>Diesel</option>
                <option>Hybrid</option>
                <option>Electric</option>
              </select>
            </div>
          <fieldset>
            <legend className="contents text-base font-medium text-gray-900">Car type  </legend>
            <p className="text-sm text-gray-500"></p>
            <div className="mt-4 space-y-4">
              <div className="flex items-center">
                <input
                  id="push-everything"
                  name="push-notifications"
                  type="radio"
                  className="focus:ring-carbonblue-light h-4 w-4 text-carbonblue-light border-gray-300"
                  checked
                />  
                <label htmlFor="push-everything" className="ml-3 block text-sm font-medium text-gray-700">
                  Small
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="push-email"
                  name="push-notifications"
                  type="radio"
                  className="focus:ring-carbonblue-light h-4 w-4 text-carbonblue-light border-gray-300"
                />
                <label htmlFor="push-email" className="ml-3 block text-sm font-medium text-gray-700">
                  Medium
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="push-email"
                  name="push-notifications"
                  type="radio"
                  className="focus:ring-carbonblue-light h-4 w-4 text-carbonblue-light border-gray-300"
                />
                <label htmlFor="push-email" className="ml-3 block text-sm font-medium text-gray-700">
                  Large
                </label>
              </div>
            </div>
          </fieldset>
          <div className="flex justify-end">





    <button onClick={() => setStage(4)}
      className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-carbonblue-light hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    >
      Continue 
    </button>
  </div>
        </form>
      </div>
    </div>
  </div>

</div>
  }




      {/* stage 3 */}
      { stage === 3 &&
<div>        <h2 className="mt-0 mb-6 md:mt-6 text-3xl leading-8  sm:text-4xl md:mb-4  text-center">Journey information </h2>
        <p className="mt-1 text-xl text-gray-900  text-center">Select number of passengers and journey type</p>
    <div className="space-y-6">

      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6 bg-gray-100 mt-4">

        <div className="md:grid md:grid-cols-1 md:gap-6 mt-6">
          <div className="max-w-md w-full m-auto">
            <form className="space-y-6" action="#" method="POST">
            <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="country" className="block text-xl  text-gray-700">
                    Number of passengers
                  </label>
                  <select
                    id="country"
                    name="country"
                    autoComplete="country-name"
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xl"
                  >
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                    <option>13</option>
                    <option>14</option>
                    <option>15</option>
                    <option>16</option>
                    <option>17</option>
                    <option>18</option>
                    <option>19</option>
                    <option>20</option>
                  </select>
                </div>
              <fieldset>
                <legend className="contents text-xl text-gray-900">Journey type  </legend>
                <p className="text-sm text-gray-500"></p>
                <div className="mt-4 space-y-4">
                  <div className="flex items-center">
                    <input
                      id="push-everything"
                      name="push-notifications"
                      type="radio"
                      className="focus:ring-carbonblue-light h-4 w-4 text-carbonblue-light border-gray-300"
                      checked
                    />  
                    <label htmlFor="push-everything" className="ml-3 block text-xl text-gray-700">
                      Single
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      id="push-email"
                      name="push-notifications"
                      type="radio"
                      className="focus:ring-carbonblue-light h-4 w-4 text-carbonblue-light border-gray-300"
                    />
                    <label htmlFor="push-email" className="ml-3 block text-xl text-gray-700">
                      Return
                    </label>
                  </div>
           
                </div>
              </fieldset>
              <div className="flex justify-end">





        <button onClick={() => setStage(4)}
          className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-xl font-medium rounded-md text-white bg-carbonblue-light hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Continue 
        </button>
      </div>
            </form>
          </div>
        </div>
      </div>

    </div></div>
      }

            {/* stage 4 */}
            { stage === 4 &&
              <div>        <h2 className="mt-0 mb-6 md:mt-6 text-3xl leading-8  sm:text-4xl md:mb-4 text-center">Select checkout amount</h2>

<div className="grid grid-cols-1 gap-4 sm:grid-cols-2 my-10">
  {pricing.map((person) => (
    <div
      key={person.email}
      className="relative rounded-lg border border-gray-300 bg-carbongreen px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
    >
      <div className="flex-shrink-0">
        <CurrencyPoundIcon className="w-10 h-10 text-white" aria-hidden="true" />
      </div>
      <div className="flex-1 min-w-0">
        <a href="#" className="focus:outline-none" onClick={() => setStage(5)} >
          <span className="absolute inset-0" aria-hidden="true" />
          <p className="text-2xl  text-white">{person.email}</p>
          <p className="text-2xl  text-white truncate">{person.role}</p>
        </a>
      </div>
    </div>
  ))}
</div></div>
}

      {/* stage 5 */}
      { stage === 5 &&
     <div>        <h2 className="mt-0 mb-0 md:mt-6 text-3xl leading-8  sm:text-4xl  text-center">Payment</h2>
<section
          aria-labelledby="payment-heading"
          className="flex-auto overflow-y-auto px-4 pt-12 pb-16 sm:px-6 sm:pt-16 lg:px-8 lg:pt-0 lg:pb-24"
        >
          <h2 id="payment-heading" className="sr-only">
            Payment and shipping details
          </h2>

          <div className="max-w-lg mx-auto lg:pt-16">
            <button
              type="button"
              className="w-full flex items-center justify-center bg-black border border-transparent text-white rounded-md py-2 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
            >
              <span className="sr-only">Pay with Apple Pay</span>
              <svg className="h-8 w-auto" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 20">
                <path d="M9.536 2.579c-.571.675-1.485 1.208-2.4 1.132-.113-.914.334-1.884.858-2.484C8.565.533 9.564.038 10.374 0c.095.951-.276 1.884-.838 2.579zm.829 1.313c-1.324-.077-2.457.751-3.085.751-.638 0-1.6-.713-2.647-.694-1.362.019-2.628.79-3.323 2.017-1.429 2.455-.372 6.09 1.009 8.087.676.99 1.485 2.075 2.552 2.036 1.009-.038 1.409-.656 2.628-.656 1.228 0 1.58.656 2.647.637 1.104-.019 1.8-.99 2.475-1.979.771-1.122 1.086-2.217 1.105-2.274-.02-.019-2.133-.828-2.152-3.263-.02-2.036 1.666-3.007 1.742-3.064-.952-1.408-2.437-1.56-2.951-1.598zm7.645-2.76v14.834h2.305v-5.072h3.19c2.913 0 4.96-1.998 4.96-4.89 0-2.893-2.01-4.872-4.885-4.872h-5.57zm2.305 1.941h2.656c2 0 3.142 1.066 3.142 2.94 0 1.875-1.142 2.95-3.151 2.95h-2.647v-5.89zM32.673 16.08c1.448 0 2.79-.733 3.4-1.893h.047v1.779h2.133V8.582c0-2.14-1.714-3.52-4.351-3.52-2.447 0-4.256 1.399-4.323 3.32h2.076c.171-.913 1.018-1.512 2.18-1.512 1.41 0 2.2.656 2.2 1.865v.818l-2.876.171c-2.675.162-4.123 1.256-4.123 3.159 0 1.922 1.495 3.197 3.637 3.197zm.62-1.76c-1.229 0-2.01-.59-2.01-1.494 0-.933.752-1.475 2.19-1.56l2.562-.162v.837c0 1.39-1.181 2.379-2.743 2.379zM41.1 20c2.247 0 3.304-.856 4.227-3.454l4.047-11.341h-2.342l-2.714 8.763h-.047l-2.714-8.763h-2.409l3.904 10.799-.21.656c-.352 1.114-.923 1.542-1.942 1.542-.18 0-.533-.02-.676-.038v1.779c.133.038.705.057.876.057z" />
              </svg>
            </button>

            <div className="relative mt-8">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-200" />
              </div>
              <div className="relative flex justify-center">
                <span className="px-4 bg-white text-xl text-gray-500">or</span>
              </div>
            </div>

            <form className="mt-6">
              <div className="grid grid-cols-12 gap-y-6 gap-x-4">
                <div className="col-span-full">
                  <label htmlFor="email-address" className="block text-xl text-gray-700">
                    Email address
                  </label>
                  <div className="mt-1">
                    <input
                      type="email"
                      id="email-address"
                      name="email-address"
                      autoComplete="email"
                      className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div className="col-span-full">
                  <label htmlFor="name-on-card" className="block text-xl text-gray-700">
                    Name on card
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      id="name-on-card"
                      name="name-on-card"
                      autoComplete="cc-name"
                      className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div className="col-span-full">
                  <label htmlFor="card-number" className="block text-xl text-gray-700">
                    Card number
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      id="card-number"
                      name="card-number"
                      autoComplete="cc-number"
                      className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div className="col-span-8 sm:col-span-9">
                  <label htmlFor="expiration-date" className="block text-xl text-gray-700">
                    Expiration date (MM/YY)
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="expiration-date"
                      id="expiration-date"
                      autoComplete="cc-exp"
                      className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div className="col-span-4 sm:col-span-3">
                  <label htmlFor="cvc" className="block text-xl text-gray-700">
                    CVC
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="cvc"
                      id="cvc"
                      autoComplete="csc"
                      className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div className="col-span-full">
                  <label htmlFor="address" className="block text-xl text-gray-700">
                    Address
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      id="address"
                      name="address"
                      autoComplete="street-address"
                      className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div className="col-span-full sm:col-span-4">
                  <label htmlFor="city" className="block text-xl text-gray-700">
                    City
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      id="city"
                      name="city"
                      autoComplete="address-level2"
                      className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div className="col-span-full sm:col-span-4">
                  <label htmlFor="regino" className="block text-xl text-gray-700">
                    State / Province
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      id="regino"
                      name="regino"
                      autoComplete="address-level1"
                      className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div className="col-span-full sm:col-span-4">
                  <label htmlFor="postal-code" className="block text-xl text-gray-700">
                    Postal code
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      id="postal-code"
                      name="postal-code"
                      autoComplete="postal-code"
                      className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
              </div>

              <div className="mt-6 flex space-x-2">
                <div className="flex items-center h-5">
                  <input
                    id="same-as-shipping"
                    name="same-as-shipping"
                    type="checkbox"
                    defaultChecked
                    className="h-6 w-6 border-gray-300 rounded text-carbonblue focus:ring-indigo-500 mt-2"
                  />
                </div>
                <label htmlFor="same-as-shipping" className="text-xl text-gray-900">
                  Billing address is the same as shipping address
                </label>
              </div>

              <button
                type="submit"
                className="w-full mt-6 bg-carbonblue border border-transparent rounded-md shadow-sm py-2 px-4 text-2xl text-white hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Pay {total}
              </button>

              <p className="flex justify-center text-base text-gray-500 mt-6">
                <LockClosedIcon className="w-5 h-5 text-gray-400 mr-1.5" aria-hidden="true" />
                Payment details stored in plain text
              </p>
            </form>
          </div>
        </section></div>
    }
    </div>
    <GetInTouch/>
  </Layout>

  </>
)
      }

export default SecondPage
